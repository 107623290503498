import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Link, useLocation} from "react-router-dom";
import {useEffect} from "react";

const drawerWidth = 240;

function Navbar(props) {
    const {windowProps} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();

    const defaultButtonStyle = {backgroundColor: 'color.main', color: '#fff', '&:hover': {backgroundColor: 'color.hover'}};
    const selectedButtonStyle = {backgroundColor: 'color.selected', color: '#fff', '&:hover': {backgroundColor: 'color.hover'}};

    const [homeStyle, setHomeStyle] = React.useState(defaultButtonStyle);
    const [rsvpStyle, setRsvpStyle] = React.useState(defaultButtonStyle);
    const [praticalInfoStyle, setPraticalInfoStyle] = React.useState(defaultButtonStyle);

    const mobileDefaultButtonStyle = {backgroundColor: 'color.white', width: '100%', textDecoration: 'none'};
    const mobileSelectedButtonStyle = {backgroundColor: 'color.gray', width: '100%', textDecoration: 'none'};

    const [mobileHomeStyle, setMobileHomeStyle] = React.useState(mobileDefaultButtonStyle);
    const [mobileRsvpStyle, setMobileRsvpStyle] = React.useState(mobileDefaultButtonStyle);
    const [mobilePraticalInfoStyle, setMobilePraticalInfoStyle] = React.useState(mobileDefaultButtonStyle);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        if (location.pathname === '/') {
            setHomeStyle(selectedButtonStyle);
            setRsvpStyle(defaultButtonStyle);
            setPraticalInfoStyle(defaultButtonStyle);
            setMobileHomeStyle(mobileSelectedButtonStyle);
            setMobileRsvpStyle(mobileDefaultButtonStyle);
            setMobilePraticalInfoStyle(mobileDefaultButtonStyle);
        } else if (location.pathname === '/rsvp') {
            setHomeStyle(defaultButtonStyle);
            setRsvpStyle(selectedButtonStyle);
            setPraticalInfoStyle(defaultButtonStyle);
            setMobileHomeStyle(mobileDefaultButtonStyle);
            setMobileRsvpStyle(mobileSelectedButtonStyle);
            setMobilePraticalInfoStyle(mobileDefaultButtonStyle);
        } else if (location.pathname === '/info-pratique') {
            setHomeStyle(defaultButtonStyle);
            setRsvpStyle(defaultButtonStyle);
            setPraticalInfoStyle(selectedButtonStyle);
            setMobileHomeStyle(mobileDefaultButtonStyle);
            setMobileRsvpStyle(mobileDefaultButtonStyle);
            setMobilePraticalInfoStyle(mobileSelectedButtonStyle);
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle}>
            <Typography variant="h6" sx={{my: 2, textAlign: 'center'}}>
                Florence & Alexandre<br/>25 mai 2024
            </Typography>
            <Divider/>
            <List>
                <ListItem disablePadding>
                    <Link to="/" style={mobileHomeStyle}>
                        <ListItemButton sx={{color: '#000', textAlign: 'center'}}>
                            <ListItemText primary={"Accueil"}/>
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem disablePadding>
                    <Link to="/rsvp" style={mobileRsvpStyle}>
                        <ListItemButton sx={{color: '#000', textAlign: 'center'}}>
                            <ListItemText primary={"RSVP"}/>
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem disablePadding>
                    <Link to="https://www.millemercismariage.com/florencealexandre24/liste.html" style={mobileDefaultButtonStyle} target="_blank" rel="noreferrer">
                        <ListItemButton sx={{color: '#000', textAlign: 'center'}}>
                            <ListItemText primary={"Participation voyage de noces"}/>
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem disablePadding>
                    <Link to="/info-pratique" style={mobilePraticalInfoStyle}>
                        <ListItemButton sx={{color: '#000', textAlign: 'center'}}>
                            <ListItemText primary={"Informations pratiques"}/>
                        </ListItemButton>
                    </Link>
                </ListItem>
            </List>
        </Box>
    );

    const container = windowProps !== undefined ? () => windowProps().document.body : undefined;

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar component="nav" color="color">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon sx={{color: 'color.white'}}/>
                    </IconButton>
                    <Box sx={{flexGrow: 1}}>
                        {/*Desktop*/}
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{display: {xs: 'none', sm: 'block'}, color: 'color.white'}}
                        >
                            Florence & Alexandre - 25 mai 2024
                        </Typography>
                        {/*Mobile*/}
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{textAlign: 'center', display: {xs: 'block', sm: 'none'}, color: 'color.white'}}
                        >
                            Florence & Alexandre - 25 mai 2024
                        </Typography>
                    </Box>
                    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                        <Link to="/" style={{textDecoration: 'none', marginLeft: 4, marginRight: 4}}>
                            <Button sx={homeStyle}>
                                Accueil
                            </Button>
                        </Link>
                        <Link to="/rsvp" style={{textDecoration: 'none', marginLeft: 4, marginRight: 4}}>
                            <Button sx={rsvpStyle}>
                                RSVP
                            </Button>
                        </Link>
                        <Link to="https://www.millemercismariage.com/florencealexandre24/liste.html" style={{textDecoration: 'none', marginLeft: 4, marginRight: 4}} target="_blank" rel="noreferrer">
                            <Button sx={defaultButtonStyle}>
                                Participation voyage de noces
                            </Button>
                        </Link>
                        <Link to="/info-pratique" style={{textDecoration: 'none', marginLeft: 4, marginRight: 4}}>
                            <Button sx={praticalInfoStyle}>
                                Informations pratiques
                            </Button>
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Box component="main">
                <Toolbar/>
            </Box>
        </Box>
    );
}


export default Navbar;