import React from "react";
import {Outlet} from "react-router-dom";
import Navbar from "./Navbar";
import {Box, Typography} from "@mui/material";

const Layout = () => {
    return (
        <>
            <Navbar/>
            <Outlet/>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'color.main',
                color: '#000',
                textAlign: 'center',
                py: 1
            }}>
                <Typography variant="small">
                    Made with ❤️ by Corentin
                </Typography>
            </Box>
        </>
    );
};
export default Layout;