import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createTheme, ThemeProvider} from "@mui/material";
import reportWebVitals from "./reportWebVitals";
import {Route, BrowserRouter, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Rsvp from "./pages/Rsvp";
import PraticalInfo from "./pages/PraticalInfo";
import Layout from "./component/Layout";

export const theme = createTheme({
    typography: {
        // TODO : Retirer la dépendance à Google Fonts
        fontFamily: [
            'Oswald',
            'Great Vibes',
            'cursive'
        ].join(','),
        // font policy :"
        // Nom ou date : Dancing script
        // Autre texte : Oswald, Roboto Condensed
    },
    palette: {
        type: 'light',
        primary: {
            main: '#FFD770',
        },
        color: {
            main: '#415A35',
            yellow: '#FFD770',
            lightGreen: '#CADCBA',
            selected: '#5b7e49',
            hover: '#78a661',
            black: '#000',
            white: '#FFF',
            gray: '#BBB',
        },
    }
})

const root = ReactDOM.createRoot(document.getElementById('root'));

function NoPage() {
    window.location.href = "/";
}

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Home/>}/>
                        <Route path="rsvp" element={<Rsvp/>}/>
                        <Route path="info-pratique" element={<PraticalInfo/>}/>
                        <Route path="*" element={<NoPage/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
