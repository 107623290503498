import * as React from 'react';
import {Box, Button, Grid, Typography, Divider} from "@mui/material";
import Countdown from 'react-countdown';
import {Link} from "react-router-dom";

function Home() {
    // Random component
    const Completionist = () => <span>You are good to go!</span>;

    // Renderer callback with condition
    const renderer = ({days, hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a completed state
            return <Completionist/>;
        } else {
            const numberStyle = {typography: {xs: 'h4', sm: 'h3'}};
            const labelStyle = {typography: {xs: 'body1', sm: 'h5'}};
            // Render a countdown
            return (
                <>
                    {/*<Box backgroundColor="color.yellow" sx={{borderRadius: 5, border: 3, borderColor: 'white'}}>*/}
                    <Box backgroundColor="white" sx={{borderRadius: 5, color: 'color.main', zIndex: 2}}>
                        <Box sx={{m: 2, width: {xs: 300, sm: 450}, display: {xs: 'block', sm: 'block'}, textAlign: 'center'}}>
                            <Grid container>
                                <Grid items xs={2}>
                                    {/*<Typography variant="h3">*/}
                                    <Typography sx={numberStyle}>
                                        {days}
                                    </Typography>
                                    <Typography sx={labelStyle}>
                                        Jours
                                    </Typography>
                                </Grid>
                                <Grid items xs={1} sx={numberStyle}>:</Grid>
                                <Grid items xs={2}>
                                    <Typography sx={numberStyle}>
                                        {hours}
                                    </Typography>
                                    <Typography sx={labelStyle}>
                                        Heures
                                    </Typography>
                                </Grid>
                                <Grid items xs={1} sx={numberStyle}>:</Grid>
                                <Grid items xs={2}>
                                    <Typography sx={numberStyle}>
                                        {minutes}
                                    </Typography>
                                    <Typography sx={labelStyle}>
                                        Minutes
                                    </Typography>
                                </Grid>
                                <Grid items xs={1} sx={numberStyle}>:</Grid>
                                <Grid items xs={3}>
                                    <Typography sx={numberStyle}>
                                        {seconds}
                                    </Typography>
                                    <Typography sx={labelStyle}>
                                        Secondes
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </>
            );
        }
    };

    const buttonStyle = {
        m: 2,
        color: 'color.white',
        backgroundColor: 'color.main',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: 'color.hover'
        }
    }

    return (
        <>
            <Box component={"img"} src={"img/flower.png"} sx={{position: 'absolute', zIndex: 1, width: '50vw'}}/>
            <Box component={"img"} src={"img/flower-2.png"} sx={{position: 'absolute', zIndex: 1, width: '10vw', top: 400, right: 0, display: {xs: 'none', sm: 'block'}}}/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    overflowY: 'scroll',
                    backgroundColor: 'color.lightGreen',
                    pt: 6,
                    px: 4,
                    overflowX: 'hidden'
                }}
            >
                <Countdown
                    date={new Date('2024-05-25T16:00:00')}
                    zeroPadTime={2}
                    renderer={renderer}
                />
                <Box sx={{m: 4, zIndex: 2}}>
                    <Typography color="color.main" sx={{textAlign: 'center', fontSize: {xs: '5rem', sm: '8rem'}, fontFamily: 'Great Vibes'}}>
                        Florence & Alexandre
                    </Typography>
                </Box>
                <Box sx={{mb: 12, zIndex: 2, fontWeight: 'bold', fontStyle: 'italic'}}>
                    <Typography variant="h3" sx={{textAlign: 'center', display: {xs: 'none', sm: 'block'}}}>
                        Samedi 25 mai 2024
                    </Typography>
                    <Typography variant="h3" sx={{textAlign: 'center', display: {xs: 'block', sm: 'none'}}}>
                        Samedi<br/>25 mai 2024
                    </Typography>
                </Box>

                <Box
                    component="img"
                    src="img/home_picture.jpg"
                    sx={{
                        width: {xs: '80vw', sm: '50vw'},
                        height: 'auto',
                    }}
                />

                <Box>
                    <Typography sx={{textAlign: 'center', my: 3, color: 'color.main'}}>
                        Nous sommes heureux de vous convier à notre mariage qui aura lieu en plein coeur de la Provence, au Moulin des Gaffins.<br/><br/>
                        Nous vous accueillerons dès 16h pour une cérémonie laïque suivie d'un cockail.<br/><br/>
                        Les festivités se poursuivront ensuite autour d'un diner et d'une soirée.<br/><br/>
                        Le lendemain nous vous attendrons à partir de 11h pour profiter d'un brunch tous ensemble, autour de la piscine si la météo est agréable.<br/><br/>
                    </Typography>
                </Box>
                <Divider sx={{width: '100%', mb: 2, border: 1, borderColor: 'color.selected'}} />
                <Typography variant="h4" sx={{color: 'color.main'}}>
                    Voyage de noces
                </Typography>
                <Typography variant="h5" sx={{mt: 1, mb: .5, color: 'color.main'}}>
                    Direction l’Afrique du Sud !
                </Typography>
                <Typography variant="body1" sx={{textAlign: 'center', color: 'color.main'}}>
                    3 semaines de Road Trip pour découvrir le pays d’Ouest en Est.<br/>
                    Au programme :<br/>
                    &bull; Safaris à la découverte des Big Fives<br/>
                    &bull; Visite de Cape Town et sa péninsule<br/>
                    &bull; Traversée de la route des jardins le long de la côte<br/>
                    &bull; Randonnées dans les parcs nationaux
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        mb: 2,
                    }}>
                    <Button variant="contained" sx={buttonStyle}>
                        <Link to="/rsvp" style={{textDecoration: 'none', color: 'inherit', fontWeight: 'bold', letterSpacing: 1.5}}>
                            Répondre
                        </Link>
                    </Button>
                    <Button variant="contained" sx={buttonStyle}>
                        <Link to="https://www.millemercismariage.com/florencealexandre24/liste.html" style={{textDecoration: 'none', color: 'inherit', fontWeight: 'bold', letterSpacing: 1.5}} target="_blank" rel="noreferrer">
                            Participation<Box component={"br"} sx={{display: {xs: 'block', sm: 'none'}}}/> voyage de noces
                        </Link>
                    </Button>
                    <Button variant="contained" sx={buttonStyle}>
                        <Link to="/info-pratique" style={{textDecoration: 'none', color: 'inherit', fontWeight: 'bold', letterSpacing: 1.5}}>
                            Informations pratiques
                        </Link>
                    </Button>
                </Box>
                <Box sx={{
                    backgroundColor: "color.main",
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Grid container>
                        <Grid items sm={3}/>
                        <Grid items xs={12} sm={2} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography sx={{m: 2, color: 'white', textAlign: 'center', lineHeight: 1.7, whiteSpace: "pre"}}>
                                <Box component={"span"} sx={{fontWeight: 'bold', letterSpacing: 1.5}}>Le Moulin des Gaffins</Box><br/>
                                345 Route des Gaffins<br/>
                                84210 Althen-des-Paluds
                            </Typography>
                        </Grid>
                        <Grid items xs={12} sm={2} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Box component={"img"} src={"img/olive-branch-white.png"} sx={{width: 100}}/>
                        </Grid>
                        <Grid items xs={12} sm={2} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography sx={{m: 2, color: 'white', textAlign: 'center', lineHeight: 1.7, whiteSpace: "pre"}}>
                                <Box component={"span"} sx={{fontWeight: 'bold', letterSpacing: 1.5}}>Florence Delhon-Bugard<br/>Alexandre Mareau<br/></Box>
                                271 rue de Marcq en Baroeul<br/>
                                59290 Wasquehal
                            </Typography>
                        </Grid>
                        <Grid items sm={3}/>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default Home;
