import * as React from 'react';
import {Box, Button, Checkbox, Container, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography} from "@mui/material";
import SweetAlert2 from "react-sweetalert2";

function Rsvp() {
    // const DOMAIN = "http://localhost:8000/";
    // const DOMAIN = "https://dev.florencealexandre2024.fr/back/";
    const DOMAIN = "https://florencealexandre2024.fr/back/";

    const [email, setEmail] = React.useState("");
    const [adultNumber, setAdultNumber] = React.useState(1);
    const [childNumber, setChildNumber] = React.useState(0);

    const [adults, setAdults] = React.useState({
        "adult1": {"name": "", "presence": true, "cocktail": true, "dinner": true, "brunch": true}
    });

    const [children, setChildren] = React.useState({
        "child1": {"name": "", "presence": true}
    });

    const [comment, setComment] = React.useState("");

    const [swalProps, setSwalProps] = React.useState({});

    const clearSwalProps = (time = 1500) => {
        setTimeout(() => {
            setSwalProps({});
        }, time);
    }

    const handleChangeAdult = (event) => {
        let diff = event.target.value - adultNumber;
        if (diff > 0) {
            let newObject = {};
            for (let i = 0; i < diff; i++) {
                newObject[`adult${adultNumber + i + 1}`] = {"name": "", "presence": true, "cocktail": true, "dinner": true, "brunch": true};
            }
            setAdults({
                ...adults,
                ...newObject
            })
        } else {
            for (let i = 0; i < Math.abs(diff); i++) {
                delete adults[`adult${adultNumber - i}`];
            }
        }
        setAdultNumber(event.target.value);
    };

    const handleChangeChild = (event) => {
        let diff = event.target.value - childNumber;
        if (diff > 0) {
            let newObject = {};
            for (let i = 0; i < diff; i++) {
                newObject[`child${childNumber + i + 1}`] = {"name": "", "presence": true};
            }
            setChildren({
                ...children,
                ...newObject
            })
        } else {
            for (let i = 0; i < Math.abs(diff); i++) {
                delete children[`child${childNumber - i}`];
            }
        }
        setChildNumber(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        for (let i = 0; i < adultNumber; i++) {
            if (adults[`adult${i + 1}`]["presence"] && !adults[`adult${i + 1}`]["cocktail"] && !adults[`adult${i + 1}`]["diner"] && !adults[`adult${i + 1}`]["brunch"]) {
                setSwalProps({
                    show: true,
                    title: "Oups !",
                    text: "L'adulte " + (i + 1) + " est présent mais ne vient nul part ? 😉",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500
                });
                clearSwalProps();
                return
            }
        }
        let cookie;
        try {
            cookie = document.cookie.split(';').map(e => e.trim()).filter(e => e.split('=')[0] === "csrftoken")[0].split('=')[1];
        } catch (e) {
            cookie = "";
        }
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-CSRFToken": cookie
            },
            body: JSON.stringify({
                email: email,
                nb_adults: adultNumber,
                nb_children: childNumber,
                adults: adults,
                children: children,
                comment: comment
            })
        };
        fetch(DOMAIN + "rsvp/", options)
            .then(response => {
                if (response.status !== 201) {
                    throw new Error("Erreur lors de la requête, HTTP CODE : " + response.status + " " + response.statusText);
                }
                return response.json()
            })
            .then(data => {
                setSwalProps({
                    show: true,
                    title: "Merci !",
                    text: "Votre réponse a bien été prise en compte.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500
                });
                clearSwalProps();
                console.log("data", data);
            })
            .catch(error => {
                setSwalProps({
                    show: true,
                    title: "Oups !",
                    text: "Une erreur est survenue, veuillez réessayer.",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500
                });
                clearSwalProps();
                console.log("error", error);
            });
    };

    return (
        <>
            <Box backgroundColor="color.lightGreen" sx={{pt: 6, minHeight: '92.1vh'}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'start',
                        overflow: 'scroll',
                    }}
                >
                    <SweetAlert2 {...swalProps} />
                    <Box backgroundColor="white" sx={{p: 4, mb: 10, width: 350, borderRadius: 5}}>
                        <Typography variant="h5" sx={{mb: 4, textAlign: 'center', color: 'color.main', fontWeight: 'bold'}}>
                            Coupon réponse
                        </Typography>
                        <Typography>
                            Pour nous aider à organiser au mieux notre mariage, merci de remplir le coupon réponse ci-dessous.
                        </Typography>
                        <form style={{width: 300}} onSubmit={handleSubmit}>
                            <Box sx={{my: 2}}>
                                <TextField required id="email" type="email" label="Adresse email" variant="outlined" value={email} onInput={e => setEmail(e.target.value)} sx={{width: "80%"}}/>
                            </Box>
                            <Box sx={{my: 2}} fullWidth>
                                Nombre d'adultes à renseigner :
                                <Container sx={{m: 0, p: 0, pl: {xs: 0, sm: 0}, width: {xs: 75, sm: 120}}}>
                                    <Select
                                        labelid="adult_select_label"
                                        id="adult_select"
                                        value={adultNumber}
                                        label="Nombre d'adultes"
                                        onChange={handleChangeAdult}
                                        fullWidth
                                        sx={{m: 0, pl: 0}}
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                    </Select>
                                </Container>
                            </Box>
                            <Box sx={{my: 2, pl: 0}} fullWidth>
                                Nombre d'enfants à renseigner :
                                <Container sx={{m: 0, p: 0, pl: {xs: 0, sm: 0}, width: {xs: 75, sm: 120}}}>
                                    <Select
                                        defaultValue={0}
                                        labelid="child_select_label"
                                        id="child_select"
                                        value={childNumber}
                                        label="Nombre d'enfants"
                                        onChange={handleChangeChild}
                                        fullWidth
                                        sx={{m: 0, pl: 0}}
                                    >
                                        <MenuItem value={0}>0</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                    </Select>
                                </Container>
                            </Box>
                            {adultNumber > 0 && Array.from({length: adultNumber}, (_, i) => {
                                return (
                                    <Box sx={{my: 2}} key={i}>
                                        Adulte {i + 1}
                                        <Box fullWidth sx={{display: 'flex', flexDirection: 'column', my: 1}}>
                                            <TextField
                                                required
                                                id={`adult${i + 1}`}
                                                label={`Nom de l'adulte ${i + 1}`}
                                                onChange={(e) => {
                                                    setAdults({
                                                        ...adults,
                                                        [`adult${i + 1}`]: {
                                                            ...adults[`adult${i + 1}`],
                                                            name: e.target.value
                                                        }
                                                    })
                                                }}
                                                variant="outlined"
                                                sx={{width: "80%"}}
                                            />
                                            <RadioGroup
                                                row
                                                defaultValue="true"
                                                onChange={() => {
                                                    setAdults({
                                                        ...adults,
                                                        [`adult${i + 1}`]: {
                                                            ...adults[`adult${i + 1}`],
                                                            presence: !adults[`adult${i + 1}`]["presence"]
                                                        }
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="true" control={<Radio/>} label="Présent"/>
                                                <FormControlLabel value="false" control={<Radio/>} label="Absent"/>
                                            </RadioGroup>
                                            {adults[`adult${i + 1}`]["presence"] && (<>
                                                <FormControlLabel control={<Checkbox
                                                    checked={adults[`adult${i + 1}`]["cocktail"]}
                                                    onChange={() => {
                                                        setAdults({
                                                            ...adults,
                                                            [`adult${i + 1}`]: {
                                                                ...adults[`adult${i + 1}`],
                                                                cocktail: !adults[`adult${i + 1}`]["cocktail"]
                                                            }
                                                        })
                                                    }}
                                                />} label="Cocktail"/>

                                                <FormControlLabel control={<Checkbox
                                                    checked={adults[`adult${i + 1}`]["dinner"]}
                                                    onChange={() => {
                                                        setAdults({
                                                            ...adults,
                                                            [`adult${i + 1}`]: {
                                                                ...adults[`adult${i + 1}`],
                                                                dinner: !adults[`adult${i + 1}`]["dinner"]
                                                            }
                                                        })
                                                    }}
                                                />} label="Dîner"/>
                                                <FormControlLabel control={<Checkbox
                                                    checked={adults[`adult${i + 1}`]["brunch"]}
                                                    onChange={() => {
                                                        setAdults({
                                                            ...adults,
                                                            [`adult${i + 1}`]: {
                                                                ...adults[`adult${i + 1}`],
                                                                brunch: !adults[`adult${i + 1}`]["brunch"]
                                                            }
                                                        })
                                                    }}
                                                />} label="Brunch du lendemain"/>
                                                <Typography style={{
                                                    fontWeight: 'bold'
                                                }}>
                                                    {Boolean(adults[`adult${i + 1}`]["presence"] && !adults[`adult${i + 1}`]["cocktail"] && !adults[`adult${i + 1}`]["dinner"] && !adults[`adult${i + 1}`]["brunch"])
                                                        ? "Présent mais ne vient nul part ? 😉"
                                                        : ""}
                                                </Typography>
                                                <br/>
                                            </>)}
                                        </Box>
                                    </Box>
                                )
                            })}
                            {childNumber > 0 && Array.from({length: childNumber}, (_, i) => {
                                return (
                                    <Box sx={{my: 2}} key={i}>
                                        Enfant {i + 1}
                                        <Box fullWidth sx={{my: 1}}>
                                            <TextField
                                                required
                                                id={`child${i + 1}`}
                                                label={`Nom de l'enfant ${i + 1}`}
                                                onChange={(e) => {
                                                    setChildren({
                                                        ...children,
                                                        [`child${i + 1}`]: {
                                                            ...children[`child${i + 1}`],
                                                            name: e.target.value
                                                        }
                                                    })
                                                }}
                                                variant="outlined"
                                                sx={{width: "80%"}}
                                            />
                                            <RadioGroup
                                                row
                                                defaultValue="present"
                                                onChange={() => {
                                                    setChildren({
                                                        ...children,
                                                        [`child${i + 1}`]: {
                                                            ...children[`child${i + 1}`],
                                                            presence: !children[`child${i + 1}`]["presence"]
                                                        }
                                                    })
                                                }}
                                            >
                                                <FormControlLabel value="present" control={<Radio/>} label="Présent"/>
                                                <FormControlLabel value="absent" control={<Radio/>} label="Absent"/>
                                            </RadioGroup>
                                        </Box>
                                        <br/>
                                    </Box>
                                )
                            })}
                            <small>* champs obligatoires</small>
                            <Box component={"div"}>
                                <TextField
                                    variant="outlined"
                                    label="Commentaire (facultatif)"
                                    multiline
                                    rows={4}
                                    sx={{width: "80%", mt: 2}}
                                    onChange={(e) => {
                                        setComment(e.target.value)
                                    }}
                                />
                            </Box>
                            <Button sx={{margin: 2}} variant="contained" type="submit">Valider</Button>
                        </form>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Rsvp;
