import * as React from 'react';
import {Box, Typography} from "@mui/material";

function ParticalInfo() {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    minHeight: '100vh',
                    pt: 6,
                    backgroundColor: 'color.lightGreen',
                }}
            >
                <Typography variant="h3" sx={{m: 2, textAlign: 'center', color: 'color.main'}}>
                    Informations pratiques
                </Typography>

                <Typography variant="h4" sx={{mt: 2, mb: 1, color: 'color.main'}}>
                    Enfants
                </Typography>
                <Typography variant="body1" sx={{textAlign: 'center'}}>
                    En raison d'un nombre d'enfants/bébés autorisés limité par le domaine, merci de nous consulter à ce propos.<br/>
                    Ne vous en faites pas, vos enfants seront très heureux de passer un week end sans leurs parents ;)
                </Typography>

                <Box component={"img"} src={"img/olive-branch.png"} sx={{width: 75, m: 4}}/>

                <Typography variant="h4" sx={{color: 'color.main'}}>
                    Itinéraire
                </Typography>
                <Typography variant="h5" sx={{mt: 1, mb: .5, color: 'color.main'}}>
                    En train
                </Typography>
                <Typography variant="body1" sx={{textAlign: 'center'}}>
                    Arrivée à la gare d'Avignon TGV<br/>
                    Puis 25 à 30 minutes de route (20km)<br/>
                    Location de voitures à la gare<br/>
                    Taxis ou Uber disponibles depuis la gare ou le domaine<br/>
                    Transports en commun disponibles avec correspondance, jusqu’à l’arrêt Beauchamp à 1km du domaine (prévoir 1h15 de trajet)
                </Typography>
                <Typography variant="h5" sx={{mt: 1, mb: .5, color: 'color.main'}}>
                    En voiture
                </Typography>
                <Typography variant="body1" sx={{textAlign: 'center'}}>
                    Suivez votre GPS ;)
                </Typography>
                <Typography variant="h5" sx={{mt: 1, mb: .5, color: 'color.main'}}>
                    Parking
                </Typography>
                <Typography variant="body1" sx={{textAlign: 'center'}}>
                    Le domaine dispose d'un parking sur place
                </Typography>

                <Box component={"img"} src={"img/olive-branch.png"} sx={{width: 75, m: 4}}/>

                <Typography variant="h4" sx={{mb: 1, color: 'color.main'}}>
                    Hébergement
                </Typography>
                <Typography variant="body1" sx={{textAlign: 'center'}}>
                    La région regorge d'hébergements touristiques en tous genres.<br/>
                    Mais attention, les locations de gites se font très souvent à la semaine.<br/>
                    Vous trouverez de nombreux hotels tous budgets dans un rayon de quelques km.
                </Typography>
                <Typography variant="body2" sx={{textAlign: 'center', mt: 2, mb: 8}}>
                    <Box component={"span"} sx={{textDecoration: 'underline'}}>Voici une liste non exhaustive : </Box>
                    <br/>
                    <Box component={"span"} sx={{fontStyle: 'italic'}}>
                        Logis Hotel La Bastide d'entraigues à Entraigues sur la Sorgues (2km)<br/>
                        Hotel Moulin de la Roque à Althen des Paluds (2km)<br/>
                        Hotel Novotel Avignon Nord à Sorgues (7km)<br/>
                        Hotel Le blason de Provence à Monteux (7km)<br/>
                        Grand choix d'hotels dans la zone industrielle du Pontet, entre Avignon et le domaine : Kyriad, B&B, Ibis, etc (8km)
                    </Box>
                </Typography>

                {/*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6113.745173575512!2d4.941876289269767!3d44.013058907111386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b58cef2ff4e217%3A0xdd59e312d0989562!2sLe%20Moulin%20des%20Gaffins!5e0!3m2!1sfr!2sfr!4v1703456262987!5m2!1sfr!2sfr" title="Maps Moulin des Gaffins" style={{border: 0, width: 600, height: 450}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
                {/*<iframe*/}
                {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2869.3845830123773!2d4.944593576569544!3d44.01344662905628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b58cef2ff4e217%3A0xdd59e312d0989562!2sLe%20Moulin%20des%20Gaffins!5e0!3m2!1sfr!2sfr!4v1703541557842!5m2!1sfr!2sfr"*/}
                {/*    width="600"*/}
                {/*    height="450"*/}
                {/*    style={{border: 0}}*/}
                {/*    allowFullScreen=""*/}
                {/*    loading="lazy"*/}
                {/*    referrerPolicy="no-referrer-when-downgrade"*/}
                {/*/>*/}
                {/*<Box*/}
                {/*    component={"iframe"}*/}
                {/*    src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2869.3845830123773!2d4.944593576569544!3d44.01344662905628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b58cef2ff4e217%3A0xdd59e312d0989562!2sLe%20Moulin%20des%20Gaffins!5e0!3m2!1sfr!2sfr!4v1703541557842!5m2!1sfr!2sfr"}*/}
                {/*    // sx={{border: 0, width: '50vw', height: '200px'}}*/}
                {/*    sx={{border: 0}}*/}
                {/*    width={600}*/}
                {/*    height={600}*/}
                {/*    allowFullScreen=""*/}
                {/*    loading="lazy"*/}
                {/*    referrerPolicy="no-referrer-when-downgrade"*/}
                {/*/>*/}
                {/*<div style={{width: '1000px', height: '750px', marginBottom: '3rem'}}>*/}
                <Box component={"div"} sx={{height: '750px', marginBottom: '3rem', display: {xs: 'block', sm: 'none'}}}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11477.539034411437!2d4.947169!3d44.013443!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b58cef2ff4e217%3A0xdd59e312d0989562!2sLe%20Moulin%20des%20Gaffins!5e0!3m2!1sfr!2sfr!4v1704228674620!5m2!1sfr!2sfr"
                        width="400"
                        height="750"
                        style={{border:0}}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title={"Maps Moulin des Gaffins"}
                    >
                    </iframe>
                </Box>
                <Box component={"div"} sx={{height: '750px', marginBottom: '3rem', display: {xs: 'none', sm: 'block'}}}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11477.539034411437!2d4.947169!3d44.013443!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b58cef2ff4e217%3A0xdd59e312d0989562!2sLe%20Moulin%20des%20Gaffins!5e0!3m2!1sfr!2sfr!4v1704228674620!5m2!1sfr!2sfr"
                        width="1000"
                        height="750"
                        style={{border:0}}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title={"Maps Moulin des Gaffins"}
                    >
                    </iframe>
                </Box>
            </Box>
        </>
);
}

export default ParticalInfo;
